<footer>
    <div id="top-footer" class="section-padding">
        <div class="footer-element">
            <img class="footer-logo" src="../../assets/images/logo-final-white.png" alt="LOGO"><br><br>
            <span class="footer-text">
                Smatramo da je potreba čoveka za razumevanjem njegovog stanja iskonska
                koliko i samo postojanje ljudskog roda. Zato ako želite da saznate sve o vašem zdravlju
                kroz stručan pregled i toplu reč ordinacija “Kardiovaskular plus” je tu za Vas.
            </span>
        </div>

        <div class="footer-element">
            <span>
                <span class="section-title"><i class="material-icons">home</i> <b>Adresa:</b> </span><br>
                Dragoljuba Rakića BB,<br>
                Prokuplje, Srbija<br><br>

                <span class="section-title"><i class="material-icons">phone</i> <b>Telefon:</b> </span><br>
                027/32 47 22<br>
                062/32 47 22<br><br>
            </span>
        </div>

        <div class="footer-element">
            <span>
                <span class="section-title"><i class="material-icons">email</i> <b>E-mail:</b> </span><br>
                kardiovaskularplus@gmail.com<br><br>

                <span class="section-title"><i class="material-icons">query_builder</i> <b>Radno vreme:</b> </span><br>
                Ponedeljak: &nbsp; 08:00 - 15:00<br>
                Utorak: &nbsp; 10:00 - 15:00; 16:00 - 20:00<br>
                Sreda: &nbsp; 08:00 - 15:00<br>
                Četvrtak: &nbsp; 10:00 - 15:00; 16:00 - 20:00<br>
                Petak: &nbsp; 08:00 - 15:00<br>
                Subota: &nbsp; 08:00 - 15:00
            </span>
        </div>

        <div class="footer-element">
            <span class="footer-link" *ngFor="let item of menuItems">
                <a [routerLink]="item.path">{{ item.name }}</a>
                <div class="separator"></div>
            </span>
        </div>

    </div>

    <div id="bottom-footer">
        <span>© {{ currentYear }} "Kardiovaskular plus". &nbsp; Sva prava zadržana.</span>
    </div>
</footer>