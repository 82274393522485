import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  services: Array<any>;

  constructor() {
    this.services = [
      {
        name: 'Kardiološki pregled',
        price: '4000 din'
      },
      {
        name: 'Ultrazvuk srca',
        price: '4000 din'
      },
      {
        name: 'Test opterećenja',
        price: '4000 din'
      },
      {
        name: 'Stres test',
        price: '5000 din'
      },
      {
        name: 'Farmakološki stres test',
        price: '5000 din'
      },
      {
        name: 'Holter EKG-a',
        price: '4000 din'
      },
      {
        name: 'Holter krvnog pritiska',
        price: '4000 din'
      },
      {
        name: 'Dopler krvnih sudova vrata',
        price: '3000 din'
      },
      {
        name: 'Dopler vena',
        price: '3000 din'
      },
      {
        name: 'Dopler arterija',
        price: '3000 din'
      },
      {
        name: 'Dopler arterija i vena',
        price: '5000 din'
      },
      {
        name: 'Eho štitne žlezde',
        price: '3000 din'
      },
      {
        name: 'Eho zglobova',
        price: '3000 din'
      },
      {
        name: 'Eho abdomena',
        price: '3000 din'
      },
      {
        name: 'Eho mekih tkiva',
        price: '3000 din'
      },
      {
        name: 'Pregled gastroenterologa',
        price: '3000 din'
      },
      {
        name: 'Pregled endokrinologa',
        price: '3000 din'
      },
      {
        name: 'Pregled vaskularnog hirurga',
        price: '3000 din'
      },
      {
        name: 'Pregled reumatologa',
        price: '3000 din'
      }
    ];
  }

  ngOnInit(): void {
  }

}
