<div class="page-title section-padding">
    <h1>KONTAKT</h1>
</div>
<div id="contact-section" class="section-padding">
    <h2>Pošaljite nam poruku:</h2>
    <div class="form">
        <div class="personal-info">
            <span *ngIf="nameError" class="error-message">*Ime i prezime su obavezni.</span>
            <input class="input-field" type="text" placeholder="Ime i prezime*" [(ngModel)]="name">

            <span *ngIf="emailError" class="error-message">*E-mail je obavezan.</span>
            <input class="input-field" type="email" placeholder="E-mail*" [(ngModel)]="email">

            <span *ngIf="phoneError" class="error-message">*Telefon je obavezan.</span>
            <input class="input-field" type="text" placeholder="Telefon*" [(ngModel)]="phone">

            <span *ngIf="messageError" class="error-message">*Poruka je obavezna.</span>
            <textarea class="message-field" name="message" placeholder="Poruka*" cols="30" rows="10" [(ngModel)]="message"></textarea>

            <button class="main-button-dark" (click)="sendMail()">Pošalji</button>
        </div>

        <div class="contact-info">
            <span style="font-size: 14pt;">
                <span class="section-title"><i class="material-icons">phone</i> Telefon:</span><br>
                027/32 47 22<br>
                062/32 47 22<br><br>
                <!-- <a href="tel:+38127324722">027/32 47 22</a> -->

                <span class="section-title"><i class="material-icons">email</i> E-mail:</span><br>
                kardiovaskularplus@gmail.com <br><br>

                <span class="section-title"><i class="material-icons">home</i> Adresa:</span><br>
                Dragoljuba Rakića BB,<br>
                Prokuplje, Srbija<br><br>

                <span class="section-title"><i class="material-icons">query_builder</i> Radno vreme:</span><br>
                Ponedeljak: &nbsp; 08:00 - 15:00<br>
                Utorak: &nbsp; 10:00 - 15:00; 16:00 - 20:00<br>
                Sreda: &nbsp; 08:00 - 15:00<br>
                Četvrtak: &nbsp; 10:00 - 15:00; 16:00 - 20:00<br>
                Petak: &nbsp; 08:00 - 15:00<br>
                Subota: &nbsp; 08:00 - 15:00
            </span>
        </div>
    </div>
</div>

<iframe
    width="100%"
    height="300"
    frameborder="0" style="border:0"
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAfkAmefLMUrbodQ0tmgabFN7hbBqlooy4&q=Kardiovaskular+plus,Dragoljuba+Rakica+bb" allowfullscreen>
</iframe>
